'use client';

import React, { ReactElement, useEffect } from 'react';
import { FiAlertCircle as AlertCircleIcon, FiShoppingCart as ShoppingCartIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { toastApi } from '@/lib/context/toasts';
import { useMiniCart } from './hooks/useMiniCart';
import Price from '@/components/Price';
import StockStatusMessage from '@/components/StockStatusMessage';
import ShippingSummary from '@/components/PriceSummary/shippingSummary';
import TaxSummary from '@/components/PriceSummary/taxSummary';
import ProductList from './modules/ProductList';
import MiniCartSkeleton from './modules/Skeleton/miniCart';
import { ToastEnum } from '@/lib/context/toasts/toastApi';
import ButtonLink from '@/components/Button/ButtonLink';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { urls } from '@/constants';
import classes from './styles/miniCart.module.scss';

const MINUS_SYMBOL = '-';

interface IMiniCartProps {
    itemCount: number;
    setIsOpen: (value: boolean) => void;
}

const MiniCart = ({ itemCount, setIsOpen }: IMiniCartProps): ReactElement => {
    const {
        configurableThumbnailSource,
        confirmRemoveItemId,
        errorMessage,
        errors,
        handleCancelRemoveItem,
        handleConfirmRemoveItem,
        handleRemoveItem,
        isPriceIncludesTax,
        isRemovingProduct,
        isUpdatingProduct,
        prices,
        productList,
        setIsUpdatingQuantity,
        shipping,
        shouldShowSkeleton,
        storeCredit,
        totalSavingWithStoreCredit,
    } = useMiniCart();
    const closeMiniCart = () => setIsOpen(false);

    const resourceUrl = useResourceUrl();
    const tMiniCart = useTranslations('miniCart');
    const tPriceSummary = useTranslations('priceSummary');
    const tDiscountSummary = useTranslations('discountSummary');

    const orderSummaryClassName =
        isRemovingProduct || isUpdatingProduct ? classes.orderSummaryLoading : classes.orderSummary;

    const isCartEmpty = !itemCount;
    const isButtonDisabled = isRemovingProduct || isUpdatingProduct || isCartEmpty;

    useEffect(() => {
        if (errorMessage) {
            toastApi.add({
                dismissible: true,
                icon: <AlertCircleIcon />,
                message: errorMessage,
                variant: ToastEnum.error,
            });
        }
    }, [errorMessage]);

    if (shouldShowSkeleton && !isCartEmpty) {
        return <MiniCartSkeleton itemCount={itemCount} />;
    }

    if (isCartEmpty) {
        return (
            <div className={classes.emptyCart}>
                <span className={classes.iconWrapper}>
                    <ShoppingCartIcon className={classes.emptyCartIcon} />
                </span>
                <div className={classes.emptyMessage}>{tMiniCart('emptyMessage')}</div>
            </div>
        );
    }

    const orderSummary =
        totalSavingWithStoreCredit || prices || storeCredit ? (
            <div className={orderSummaryClassName} data-mt-type="mini-cart-order-summary">
                <div className={classes.stockStatusMessageContainer}>
                    <StockStatusMessage />
                </div>
                <div className={classes.orderSummaryDetails}>
                    {prices?.subtotal && (
                        <div className={classes.orderSummaryRow}>
                            {tPriceSummary('lineItemLabel')}
                            <span className={classes.price}>
                                <Price currencyCode={prices?.subtotal?.currency} value={prices?.subtotal?.value} />
                            </span>
                        </div>
                    )}
                    {prices?.totalSavings && (
                        <div className={classes.orderSummaryRow}>
                            {tDiscountSummary('lineItemLabel')}
                            <span className={classes.price}>
                                {MINUS_SYMBOL}
                                <Price
                                    currencyCode={prices?.totalSavings?.currency}
                                    value={prices?.totalSavings?.value}
                                />
                            </span>
                        </div>
                    )}
                    {storeCredit && (
                        <div className={classes.orderSummaryRow}>
                            {tPriceSummary('storeCreditLabel')}
                            <span className={classes.price}>
                                {MINUS_SYMBOL}
                                <Price currencyCode={storeCredit.currency} value={storeCredit.value} />
                            </span>
                        </div>
                    )}
                    {shipping && (
                        <div className={classes.orderSummaryRow}>
                            <ShippingSummary data={shipping} isCheckout isProActive={false} />
                        </div>
                    )}
                    {!isPriceIncludesTax && (
                        <div className={classes.orderSummaryRow}>
                            <TaxSummary data={prices?.taxes} isCheckout />
                        </div>
                    )}
                    <div className={classes.orderTotal}>
                        <span className={classes.orderSummaryTotalRow}>
                            <span>
                                {tPriceSummary('total')}
                                {isPriceIncludesTax && (
                                    <span className={classes.totalsVatText}>
                                        {''} {tPriceSummary('inclVatText')}
                                    </span>
                                )}
                            </span>
                        </span>
                        {prices?.total && (
                            <span className={`${classes.orderSummaryTotalRow} ${classes.price}`}>
                                <Price value={prices?.total?.value} currencyCode={prices?.total?.currency} />
                            </span>
                        )}
                    </div>
                </div>
                {(!shipping?.[0]?.selected_shipping_method?.amount || !shipping?.length) &&
                    (isPriceIncludesTax
                        ? tMiniCart('shippingCalculationsDisclaimer')
                        : !shipping?.length
                          ? tMiniCart('calculationsDisclaimer')
                          : tMiniCart('shippingCalculationsDisclaimer'))}
            </div>
        ) : null;

    return (
        <>
            <div className={classes.body} data-mt-type="mini-cart-body">
                <ProductList
                    items={productList}
                    handleRemoveItem={handleRemoveItem}
                    closeMiniCart={closeMiniCart}
                    configurableThumbnailSource={configurableThumbnailSource}
                    handleConfirmRemoveItem={handleConfirmRemoveItem}
                    handleCancelRemoveItem={handleCancelRemoveItem}
                    confirmRemoveItemId={confirmRemoveItemId}
                    setIsUpdatingQuantity={setIsUpdatingQuantity}
                />
            </div>
            <div className={classes.summary}>
                {orderSummary}
                <ButtonLink
                    onClick={closeMiniCart}
                    variant={'primary'}
                    isFullWidth
                    href={resourceUrl(urls.checkout)}
                    className={classes.checkoutButton}
                    disabled={isButtonDisabled || !!errors?.length}
                    prefetch
                >
                    {tMiniCart('checkout')}
                </ButtonLink>
                <ButtonLink
                    onClick={closeMiniCart}
                    variant={'secondary'}
                    href={resourceUrl(urls.cart)}
                    isFullWidth
                    className={classes.editCartButton}
                    disabled={isButtonDisabled}
                >
                    {tMiniCart('editCartButton')}
                </ButtonLink>
            </div>
        </>
    );
};

export default MiniCart;
