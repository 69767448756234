import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useUpdateMiniCartData } from '@/components/MiniCart';

import { useAppContext, useCartContext } from '@/lib/context';
import { useIsTaxIncludedInPrice } from '@/hooks/useIsTaxIncludedInPrice';
import { deriveErrorMessage } from '@/lib/Apollo/utils/deriveErrorMessage';
import { getDiscountByLabel } from '@/components/PriceSummary/hooks/usePriceSummary';
import { useCartUpdate } from '@vaimo/google-tag-manager';
import { useAddToCart } from '@vaimo/emarsys-tracking/src/talons';
import DEFAULT_OPERATIONS from '../api/miniCart.gql';
import type { IUseMiniCart } from '../types';

export const useMiniCart = (): IUseMiniCart => {
    const [confirmRemoveItemId, setConfirmRemoveItemId] = useState<string | null>(null);
    const [isUpdatingQuantity, setIsUpdatingQuantity] = useState<boolean>(false);
    const { miniCartQuery, removeItemMutation } = DEFAULT_OPERATIONS;
    const [{ cartContent, cartId }] = useCartContext();
    const { getPriceWithOrWithoutTax } = useIsTaxIncludedInPrice();

    const [{ storeConfig }] = useAppContext();
    const { configurableThumbnailSource, isPriceIncludesTax } = storeConfig;

    const { miniCartData, miniCartLoading } = useUpdateMiniCartData({
        operation: miniCartQuery,
    });

    const [removeItem, { called: removeItemCalled, error: removeItemError, loading: removeItemLoading }] =
        useMutation(removeItemMutation);

    const prices = useMemo(() => {
        if (miniCartLoading || !miniCartData) {
            return;
        }

        const totalSavings = getDiscountByLabel('Savings Total', miniCartData.cart.prices.discounts);
        const sale = getDiscountByLabel('Sale', miniCartData.cart.prices.discounts);
        const subtotal = getPriceWithOrWithoutTax({
            priceExclTax: miniCartData.cart.prices.subtotal_excluding_tax,
            priceInclTax: miniCartData.cart.prices.subtotal_including_tax,
        });
        const total = miniCartData.cart.prices.grand_total;
        const taxes = miniCartData.cart.prices.applied_taxes;

        return {
            subtotal: { ...subtotal, value: subtotal.value + (sale?.value || 0) },
            taxes,
            total,
            totalSavings,
        };
    }, [getPriceWithOrWithoutTax, miniCartData, miniCartLoading]);

    const storeCredit = useMemo(() => {
        if (miniCartLoading || !miniCartData) {
            return null;
        }

        const appliedBalance = miniCartData?.cart?.applied_store_credit?.applied_balance;

        return appliedBalance?.value ? appliedBalance : null;
    }, [miniCartData, miniCartLoading]);

    const shipping = useMemo(() => {
        if (miniCartLoading || !miniCartData) {
            return null;
        }

        return miniCartData?.cart?.shipping_addresses || [];
    }, [miniCartData, miniCartLoading]);

    const totalSavingWithStoreCredit = useMemo(() => {
        const storeCreditAppliedToCart = { value: Number(prices?.totalSavings?.value) + Number(storeCredit?.value) };

        if (storeCredit && prices?.totalSavings) {
            return storeCredit && prices?.totalSavings
                ? Object.assign(prices?.totalSavings, storeCreditAppliedToCart)
                : prices?.totalSavings;
        }
    }, [storeCredit, prices?.totalSavings]);

    const productList = useMemo(() => {
        if (!miniCartLoading && miniCartData) {
            return miniCartData.cart.items;
        }
    }, [miniCartData, miniCartLoading]);

    const handleRemoveItem = useCallback(
        async (id: string) => {
            try {
                await removeItem({
                    variables: {
                        cartId,
                        itemId: id,
                    },
                });
            } catch (e) {
                // Error is logged by apollo link - no need to double log.
            }
        },
        [cartId, removeItem],
    );

    const handleConfirmRemoveItem = useCallback((itemId: string) => setConfirmRemoveItemId(itemId), []);

    const handleCancelRemoveItem = useCallback(() => setConfirmRemoveItemId(null), []);

    const derivedErrorMessage = useMemo(() => deriveErrorMessage([removeItemError]), [removeItemError]);
    const errors = useMemo(() => miniCartData?.cart?.errors || [], [miniCartData]);

    useCartUpdate({ cartContent, cartId });
    useAddToCart();

    const isRemovingProduct = removeItemCalled && removeItemLoading;
    const shouldShowSkeleton = miniCartLoading || !productList;

    return {
        configurableThumbnailSource,
        confirmRemoveItemId,
        errorMessage: derivedErrorMessage,
        errors,
        handleCancelRemoveItem,
        handleConfirmRemoveItem,
        handleRemoveItem,
        isPriceIncludesTax,
        isRemovingProduct,
        isUpdatingProduct: isUpdatingQuantity,
        prices,
        productList,
        setIsUpdatingQuantity,
        shipping,
        shouldShowSkeleton,
        storeCredit,
        totalSavingWithStoreCredit,
    };
};
